import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function CreateTicketSuccess() {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{ display: 'flex', gap: 2, padding: 2, flexDirection: 'column' }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Portal de ayuda{' '}
          <Typography component="i" className="em-svg em-female-technologist" />
        </Typography>
        <Typography sx={{ fontSize: '1.3rem' }} align="center">
          ¡Gracias por contactarte con nosotros!
        </Typography>
        <Typography sx={{ fontSize: '1.3rem' }} align="center">
          Vamos a revisar tu solicitud para entregarte una solución lo más
          pronto posible{' '}
          <Typography component="i" className="em-svg em-white_check_mark" />
        </Typography>
      </Box>
    </Container>
  );
}
export default CreateTicketSuccess;
