import { useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useFetcher, useSearchParams, useLocation } from 'react-router-dom';

import GoogleMapsInput from '../../components/GoogleMapsInput';

function VerifyShippingAddress() {
  const [updateShippingAddressLoading, setUpdateShippingAddressLoading] =
    useState(false);
  const {
    state: { orderId, andromedaOrderId, sellerName, orderNumber },
  } = useLocation();

  const { register, handleSubmit, setValue } = useForm();
  const [searchParams] = useSearchParams();
  const linkId = searchParams.get('linkId');

  const { submit, Form } = useFetcher();

  const onSubmit = (formData) => {
    setUpdateShippingAddressLoading(true);
    submit(
      {
        linkId,
        orderId,
        andromedaOrderId,
        serializedFormData: JSON.stringify({ formData }),
      },
      { method: 'post', action: '/verify-shipping-address' },
    );
  };

  return (
    <Container>
      <Typography
        variant="body2"
        sx={{
          marginTop: 4,
          marginBottom: 2,
          color: '#888888',
          textAlign: 'justify',
          fontWeight: 200,
        }}
      >
        Reingresa la dirección de la orden
        <Typography sx={{ fontWeight: 400 }}>
          {' '}
          #{orderNumber || orderId} de {sellerName}
        </Typography>
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <GoogleMapsInput register={register} setValue={setValue} />
        <TextField
          required
          size="small"
          label="Depto/Casa"
          fullWidth
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('complement', { required: true })}
          sx={{ marginBottom: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          fullWidth
          disabled={updateShippingAddressLoading}
          endIcon={
            updateShippingAddressLoading && <CircularProgress size={15} />
          }
        >
          Enviar nueva dirección
        </Button>
      </Form>
    </Container>
  );
}

export default VerifyShippingAddress;
