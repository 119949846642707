import { useRef, useState, useEffect, useCallback } from 'react';

import { Typography, Container, Button, Box } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { useNavigate, useLoaderData } from 'react-router-dom';

import { useConfirmShippingAddress } from '../../api/address';
import getOrderQuery from '../../api/order';
import ORDER_STATUSES_ALLOWED_TO_UPDATE_ADDRESS from '../../utils/orderStatus';

const queryClient = new QueryClient();

function ConfirmShippingAddress() {
  const {
    mutateAsync: mutateConfirmShippingAddress,
    isLoading: confirmShippingAddressLoading,
  } = useConfirmShippingAddress();

  const {
    order,
    link: { linkId },
  } = useLoaderData();

  const {
    id: andromedaOrderId,
    sourceOrder: { orderId, orderNumber },
    shipping: { computedAddress },
    seller: { name: sellerName },
  } = order;

  const {
    label: addressLabel,
    location: { coordinates },
  } = computedAddress;

  const [lat, lng] = coordinates;

  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const initialMap = new window.google.maps.Map(mapRef.current, {
      center: { lat, lng },
      zoom: 17,
    });

    setMap(initialMap);
  }, [mapRef]);

  useEffect(() => {
    // eslint-disable-next-line no-new
    new window.google.maps.Marker({
      position: { lat, lng },
      map,
    });
  }, [map]);

  const confirmCurrentShippingAddress = useCallback(async () => {
    const orderQuery = getOrderQuery({ linkId, orderId: andromedaOrderId });
    const orderFromQuery = await queryClient.fetchQuery(orderQuery);
    if (
      Object.keys(ORDER_STATUSES_ALLOWED_TO_UPDATE_ADDRESS).includes(
        orderFromQuery.status,
      )
    ) {
      const {
        shipping: { computedAddress: unconfirmedComputedAddress },
        customer: { email },
      } = orderFromQuery;
      await mutateConfirmShippingAddress({
        linkId,
        andromedaOrderId,
        computedAddress: unconfirmedComputedAddress,
        isVerifiedBy: email,
      });
      navigate('/verify-shipping-address-successfully', {
        state: {
          message: `La orden #${orderId} de ${sellerName} ha sido confirmada exitosamente.`,
        },
      });
    }
  }, []);

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginX: 4,
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography sx={{ fontWeight: 200, color: '#888888' }}>
            Tienda
          </Typography>
          <Typography>{sellerName}</Typography>
        </Box>
        <Box
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography sx={{ fontWeight: 200, color: '#888888' }}>
            Orden
          </Typography>
          <Typography>{orderNumber || orderId}</Typography>
        </Box>
      </Box>

      <Typography
        variant="body2"
        sx={{
          marginBottom: 2,
          color: '#888888',
          textAlign: 'justify',
          fontWeight: 200,
        }}
      >
        ¡Estamos preparando tu pedido! Y queremos que llegue sin contratiempos.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          marginBottom: 2,
          color: '#888888',
          textAlign: 'justify',
          fontWeight: 200,
        }}
      >
        Es por eso que queremos confirmar si la dirección, que nos ha llegado
        del sitio donde compraste, ha sido correctamente localizada por nuestro{' '}
        <Typography component="i" className="em-svg em-robot_face" />.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          marginBottom: 1.5,
          color: '#888888',
          textAlign: 'justify',
          fontWeight: 200,
        }}
      >
        Tu pedido debería despacharse a:
      </Typography>

      <Typography
        variant="body2"
        sx={{ fontWeight: '500', marginBottom: 2, textAlign: 'center' }}
      >
        {addressLabel}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          marginBottom: 1.5,
          color: '#888888',
          textAlign: 'justify',
          fontWeight: 200,
        }}
      >
        Y su ubicación es:
      </Typography>
      <div
        ref={mapRef}
        id="map"
        style={{ height: '250px', marginBottom: 16 }}
      />

      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: '400',
            marginBottom: 1,
            textAlign: 'center',
            color: '262525',
          }}
        >
          ¿Confirmas esta dirección?
        </Typography>
        <Button
          variant="contained"
          disabled={confirmShippingAddressLoading}
          onClick={confirmCurrentShippingAddress}
          fullWidth
          sx={{ marginBottom: 1 }}
        >
          Sí, esta es la dirección
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(`/verify-shipping-address?linkId=${linkId}`, {
              state: { orderId, andromedaOrderId, sellerName, orderNumber },
            })
          }
          fullWidth
        >
          No, quiero cambiarla
        </Button>
      </Box>
    </Container>
  );
}

export default ConfirmShippingAddress;
