const keyMirror = require('keymirror');

const ORDER_STATUSES_ALLOWED_TO_UPDATE_ADDRESS = keyMirror({
  created: null,
  pendingApproval: null,
  pending: null,
  backorder: null,
  processing: null,
});

export default ORDER_STATUSES_ALLOWED_TO_UPDATE_ADDRESS;
