import { redirect, defer } from 'react-router-dom';

import { updateShippingAddress } from '../api/address';
import getLinkIdQuery from '../api/link';
import getOrderQuery from '../api/order';
import createTicket from '../api/ticket';
import ORDER_STATUSES_ALLOWED_TO_UPDATE_ADDRESS from '../utils/orderStatus';

const NOT_FOUND_ERROR_STATUS_CODE = 404;

export function verifyLinkIdLoader(queryClient) {
  return async ({ request }) => {
    const url = new URL(request.url);
    const linkId = url.searchParams.get('linkId');
    if (!linkId) {
      throw Error('No tienes acceso a esta página');
    }
    const linkIdQuery = getLinkIdQuery(linkId);

    let link = queryClient.getQueryData(linkIdQuery.queryKey);
    if (!link) {
      try {
        link = await queryClient.fetchQuery(linkIdQuery);
      } catch (e) {
        if (e.response.status === NOT_FOUND_ERROR_STATUS_CODE) {
          throw Error('el link ya no está disponible');
        }
      }
    }
    const orderQuery = getOrderQuery(link);
    const order = await queryClient.fetchQuery(orderQuery);
    return { link, order };
  };
}

export function updateShippingAdderssAction(queryClient) {
  return async ({ request }) => {
    const formData = await request.formData();

    const linkId = formData.get('linkId');
    const andromedaOrderId = formData.get('andromedaOrderId');

    const orderQuery = getOrderQuery({ linkId, orderId: andromedaOrderId });
    const order = await queryClient.fetchQuery(orderQuery);
    // check order status
    if (
      Object.keys(ORDER_STATUSES_ALLOWED_TO_UPDATE_ADDRESS).includes(
        order.status,
      )
    ) {
      const {
        formData: {
          complement,
          // eslint-disable-next-line camelcase
          selectedPlace: { address_components, location, formatted_address },
        },
      } = JSON.parse(formData.get('serializedFormData'));

      const { email } = order.customer;

      await updateShippingAddress({
        linkId,
        andromedaOrderId,
        // eslint-disable-next-line camelcase
        addressLabel: formatted_address,
        complement,
        // eslint-disable-next-line camelcase
        addressComponents: address_components,
        location,
        isVerifiedBy: email,
      });

      return redirect(`/verify-shipping-address-successfully`);
    }
    throw Error('¡Lo sentimos! Ya no se puede cambiar la dirección.');
  };
}

export function getOrderLoader(queryClient) {
  return async ({ request, params }) => {
    const url = new URL(request.url);
    const orderId = url.searchParams.get('orderId') || params?.orderId;
    const orderQuery = getOrderQuery({ orderId });
    const getOrderPromise = queryClient.fetchQuery(orderQuery);
    return defer({
      order: getOrderPromise,
    });
  };
}

export async function createTicketAction({ request, params }) {
  const orderId = params?.orderId;
  const formData = Object.fromEntries(await request.formData());
  try {
    await createTicket({ orderId, formData });
    return redirect('/support/orders/tickets/successfully-created');
  } catch {
    throw new Error(
      'No hemos podido registrar tu solicitud. Revisa la URL ingresada o inténtalo más tarde',
    );
  }
}
