/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useRef } from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';

function GoogleMapsInput({ register, setValue: setFormValue }) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  const getMatches = async () =>
    new Promise((resolve, reject) => {
      try {
        new window.google.maps.places.AutocompleteService().getPlacePredictions(
          {
            input: inputValue,
            types: ['address'],
            componentRestrictions: { country: ['cl', 'pe'] },
          },
          resolve,
        );
      } catch (e) {
        reject(e);
      }
    });

  const doQuery = async () => {
    const results = await getMatches();
    setOptions(results || []);
  };

  useEffect(() => {
    const initialMap = new window.google.maps.Map(mapRef.current, {
      center: { lat: -33.4043615, lng: -70.6384101 },
      zoom: 16,
    });
    setMap(initialMap);
  }, [mapRef]);

  useEffect(() => {
    if (inputValue) {
      doQuery();
    }
  }, [inputValue]);

  useEffect(() => {
    if (value) {
      const placeId = value.place_id;

      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId,
          fields: ['address_components', 'formatted_address', 'geometry'],
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const pin = new window.google.maps.Marker({
              position: place.geometry.location,
              map,
            });
            // eslint-disable-next-line no-console
            console.log(pin);
            setFormValue('selectedPlace', {
              ...place,
              location: {
                type: 'Point',
                coordinates: {
                  0: place.geometry.location.lat(),
                  1: place.geometry.location.lng(),
                },
              },
            });
            map.setCenter(place.geometry.location);
          }
        },
      );

      setOptions([value]);
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        id="google-map-demo"
        sx={{ marginBottom: 1 }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        isOptionEqualToValue={(option, selectedValue) => {
          if (!selectedValue) {
            return false;
          }
          if (typeof option === 'object') {
            return option.description === selectedValue.description;
          }
          return option === selectedValue;
        }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No locations"
        onChange={(_, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            label="Dirección"
            fullWidth
            size="small"
            {...register('addressLabel', { required: true })}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  {parts.map((part, index) => (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}

                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <input {...register('selectedPlace', { required: true })} hidden />
      <div
        ref={mapRef}
        id="map"
        style={{ height: '300px', marginBottom: 16 }}
      />
    </>
  );
}

GoogleMapsInput.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default GoogleMapsInput;
