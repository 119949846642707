/* eslint-disable no-param-reassign */
import axios from 'axios';

const { REACT_APP_SCORPIUS_BACKEND_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_SCORPIUS_BACKEND_URL,
});

api.interceptors.request.use((config) => {
  const newConfig = { ...config };

  return newConfig;
});

export default api;
