import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FC5000',
    },
  },
  typography: {
    fontFamily: 'rubik, sans-serif;',
    subtitle1: {
      fontWeight: 600,
    },
    caption: {
      color: '#656565',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 4,
        },
      },
    },
  },
});

export default theme;
