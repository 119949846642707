import { Typography, Container, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

const DEFAULT_MESSAGE =
  'La dirección de envío de la orden ha sido modificada exitosamente';

export default function ShippingAddressUpdateSuccessful() {
  const {
    state: { message },
  } = useLocation();
  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          textAlign: 'justify',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            marginBottom: 1,
            color: '#888888',
            fontWeight: 200,
            marginTop: 6,
          }}
        >
          ¡Muchas gracias por ayudarnos a confirmar tu dirección!
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginBottom: 1,
            color: '#888888',
            fontWeight: 500,
          }}
        >
          {message || DEFAULT_MESSAGE}
        </Typography>

        <Typography
          variant="body2"
          sx={{ marginBottom: 1, color: '#888888', fontWeight: 200 }}
        >
          Estamos trabajando para que tu pedido llegue lo más pronto posible y
          puedas disfrutar de tus productos.
        </Typography>
      </Box>
    </Container>
  );
}
