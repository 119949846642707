import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function ItemContainer({ label, value }) {
  return (
    <Box
      sx={() => ({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      })}
    >
      <Typography variant="caption" align="center">
        {label}
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ color: grey[800] }}>
        {value}
      </Typography>
    </Box>
  );
}
ItemContainer.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
export default ItemContainer;
