import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

const { REACT_APP_STORE_CENTRAL_LOGO } = process.env;

function Root() {
  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', marginTop: 6 }}>
      <Box
        component="img"
        src={REACT_APP_STORE_CENTRAL_LOGO}
        alt="logo"
        sx={{
          height: 'auto',
          width: 125,
          alignSelf: 'center',
        }}
      />
      <Outlet />
    </Container>
  );
}

export default Root;
