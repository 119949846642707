import api from '.';

const getOrder = ({ orderId, linkId }) =>
  api({
    method: 'GET',
    url: `/orders/${orderId}`,
    data: {
      linkId,
    },
  }).then((response) => response.data);

const getOrderQuery = (q) => ({
  queryKey: ['getOrder'],
  queryFn: () => getOrder(q),
});

export default getOrderQuery;
