import { useState, Suspense } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { grey } from '@mui/material/colors';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useLoaderData, useFetcher, Await } from 'react-router-dom';

import SupportFormSkeleton from './SupportFormSkeleton';
import ItemContainer from '../../components/ItemContainer';

function SupportForm() {
  const data = useLoaderData();
  const [createIssueLoading, setCreateIssueLoading] = useState(false);
  const { Form, submit } = useFetcher();
  const { register, handleSubmit } = useForm();
  const onSubmit = (formData) => {
    setCreateIssueLoading(true);
    submit(formData, { method: 'post' });
  };
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          padding: 2,
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5" align="center">
          Portal de ayuda{' '}
          <Typography component="i" className="em-svg em-female-technologist" />
        </Typography>
        <Suspense fallback={<SupportFormSkeleton />}>
          <Await resolve={data.order}>
            {({
              seller: { name: sellerName },
              sourceOrder: { orderNumber, orderId },
              customer: { firstName, lastName, email, phone },
            }) => (
              <>
                <Typography
                  sx={{ fontSize: '1.1rem', color: grey[800] }}
                  variant="body1"
                  align="center"
                  gutterBottom
                >
                  ¡Hola! Acá puedes dejarnos tu pregunta o reportarnos algún
                  problema con tu pedido{' '}
                  <Typography component="i" className="em-svg em-package" />:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'wrap',
                    justifyContent: 'space-between',
                    gap: 3,
                  }}
                >
                  <ItemContainer label="Tienda" value={sellerName} />
                  <ItemContainer
                    label="Número orden"
                    value={orderNumber || orderId}
                  />
                </Box>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 3,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="body1" sx={{ pt: 2 }}>
                      Datos de contacto{' '}
                      <Typography component="i" className="em-svg em-iphone" />
                    </Typography>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        gap: 3,
                        [theme.breakpoints.down('sm')]: {
                          flexDirection: 'column',
                        },
                      })}
                    >
                      <TextField
                        required
                        size="small"
                        label="Ingresa tu nombre"
                        sx={{ width: '100%' }}
                        defaultValue={firstName}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('firstName', { required: true })}
                      />
                      <TextField
                        required
                        size="small"
                        label="Ingresa tu apellido"
                        sx={{ width: '100%' }}
                        defaultValue={lastName}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('lastName', { required: true })}
                      />
                    </Box>
                    <TextField
                      required
                      size="small"
                      label="Ingresa tu correo"
                      multiline
                      defaultValue={email}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('email', { required: true })}
                    />
                    <TextField
                      required
                      size="small"
                      label="Ingresa tu número de contacto"
                      fullWidth
                      defaultValue={phone}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('phone', { required: true })}
                    />
                    <Typography variant="body1" sx={{ pt: 2 }}>
                      Solicitud{' '}
                      <Typography
                        component="i"
                        className="em-svg em-page_facing_up"
                      />
                    </Typography>
                    <TextField
                      required
                      size="small"
                      label="Ingresa tu solicitud"
                      multiline
                      rows={3}
                      fullWidth
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('comment', { required: true })}
                      placeholder="¿Cuál es el estado de mi pedido?"
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={createIssueLoading}
                      endIcon={
                        createIssueLoading && <CircularProgress size={15} />
                      }
                    >
                      Enviar solicitud
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Await>
        </Suspense>
      </Box>
    </Container>
  );
}
export default SupportForm;
