import { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter } from 'react-router-dom';

import {
  verifyLinkIdLoader,
  updateShippingAdderssAction,
  getOrderLoader,
  createTicketAction,
} from './root';
import ErrorPage from '../ErrorPage';
import Root from '../Root';
import ConfirmShippingAddress from '../views/shipping-address-verification/ConfirmShippingAddress';
import ShippingAddressUpdateSuccessful from '../views/shipping-address-verification/ShippingAddressUpdateSuccessful';
import VerifyShippingAddress from '../views/shipping-address-verification/VerifyShippingAddress';
import CreateTicketSuccess from '../views/tickets/CreateTicketSuccess';
import SupportForm from '../views/tickets/SupportForm';

const queryClient = new QueryClient();

const Router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/confirm-shipping-address',
        element: <ConfirmShippingAddress />,
        loader: verifyLinkIdLoader(queryClient),
      },
      {
        path: '/verify-shipping-address',
        element: <VerifyShippingAddress />,
        action: updateShippingAdderssAction(queryClient),
      },
      {
        path: '/verify-shipping-address-successfully',
        element: <ShippingAddressUpdateSuccessful />,
      },
      {
        path: 'support/orders',
        children: [
          {
            path: ':orderId/tickets/new',
            element: <SupportForm />,
            loader: getOrderLoader(queryClient),
            action: createTicketAction,
          },
          {
            path: 'tickets/successfully-created',
            element: <CreateTicketSuccess />,
          },
        ],
      },
    ],
  },
]);

export default Router;
