import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography, Container, Box } from '@mui/material';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <ErrorOutlineIcon color="error" style={{ fontSize: '58px' }} />
        <Typography marginY={2} variant="h6">
          Ups! ha ocurrido un error.
        </Typography>

        <i>{error.statusText || error.message}</i>
      </Box>
    </Container>
  );
}
