import api from '.';

const getLinkId = (linkId) =>
  api({
    method: 'GET',
    url: `/linkId/${linkId}`,
  }).then((response) => response.data);

const getLinkIdQuery = (q) => ({
  queryKey: ['getLinkId'],
  queryFn: () => getLinkId(q),
});

export default getLinkIdQuery;
