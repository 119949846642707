import api from '.';

const createTicket = ({ orderId, formData }) =>
  api({
    method: 'POST',
    url: `/orders/${orderId}/tickets`,
    data: formData,
  });

export default createTicket;
