import { useMutation } from '@tanstack/react-query';

import api from '.';

export const updateShippingAddress = ({
  linkId,
  andromedaOrderId,
  addressLabel,
  complement,
  addressComponents,
  location,
  isVerifiedBy,
}) =>
  api({
    method: 'POST',
    url: '/update-shipping-address',
    data: {
      linkId,
      andromedaOrderId,
      addressLabel,
      complement,
      addressComponents,
      location,
      isVerifiedBy,
    },
  }).then((response) => response.data);

export const useConfirmShippingAddress = () =>
  useMutation(({ linkId, andromedaOrderId, computedAddress, isVerifiedBy }) =>
    api({
      method: 'POST',
      url: '/confirm-shipping-address',
      data: {
        linkId,
        andromedaOrderId,
        computedAddress,
        isVerifiedBy,
      },
    }).then((response) => response.data),
  );
