import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function SupportFormSkeleton() {
  return (
    <Stack spacing={5}>
      <Stack spacing={2}>
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={60} />
      </Stack>

      <Skeleton variant="rounded" height={200} />
      <Skeleton variant="rounded" height={100} />
      <Skeleton variant="rounded" height={40} />
    </Stack>
  );
}
export default SupportFormSkeleton;
